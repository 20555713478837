export enum RatingType {
  CreditworthinessWidget = 'CreditworthinessWidget',
  ComparerWidget = 'ComparerWidget',
  CashComparerWidget = 'CashComparerWidget',
  RefinanceWidget = 'RefinanceWidget',
  SafeLoanWidget = 'SafeLoanWidget',
  LoanForStartWidget = 'LoanForStartWidget',
}

export type RatingValue = IntRange<1, 5>;
export type Rating = {
  key: RatingType,
  count: number,
  average: number,
  createdAt: Date,
  updatedAt: Date
};

interface pageRatingDetails {
  name: string,
  description: string,
  url: string
}

export type PageRatingDetailsRecord = Record<RatingType, pageRatingDetails>
